<template>
  <b-card class="p-2">
    <!-- form -->
    <validation-observer ref="addfoodForm">
      <b-form @submit.prevent="submitForm">
        <h3 class="mb-2">Edit food</h3>
        <b-row>
          <!-- first name -->

          <b-col cols="6">
            <b-form-group label="Item Code" label-for="code">
              <validation-provider
                #default="{ errors }"
                name=" Name"
                rules="required"
              >
                <b-form-input
                  id="code"
                  v-model="item.code"
                  placeholder="Item Code"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Item Name" label-for="Name">
              <validation-provider
                #default="{ errors }"
                name=" Name"
                rules="required"
              >
                <b-form-input
                  id="Name"
                  v-model="item.name"
                  placeholder="Item Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="Short Description"
              label-for="ShortDescription"
            >
              <validation-provider
                #default="{ errors }"
                name=" ShortDescription"
                rules="required"
              >
                <b-form-textarea
                  id="ShortDescription"
                  placeholder="Short Description"
                  v-model="item.short_text"
                  rows="3"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group label=" Description" label-for="Description">
              <validation-provider
                #default="{ errors }"
                name=" Description"
                rules="required"
              >
                <b-form-textarea
                  id="Description"
                  placeholder=" Description"
                  v-model="item.long_text"
                  rows="3"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group label="Category" label-for="Category">
              <validation-provider
                #default="{ errors }"
                name="Category"
                rules="required"
              >
                <v-select
                  v-model="selectedcategory"
                  :reduce="(category) => category.id"
                  :options="category"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group label="Ingredient" label-for="Ingredient">
              <validation-provider
                #default="{ errors }"
                name="Ingredient"
                rules=""
              >
                <v-select
                  v-model="selectedingredient"
                  multiple
                  :reduce="(ingredient) => ingredient.id"
                  :options="ingredient"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group label="Sidedish" label-for="Sidedish">
              <validation-provider
                #default="{ errors }"
                name="Sidedish"
                rules=""
              >
                <v-select
                  v-model="selectedsidedish"
                  multiple
                  :reduce="(sidedish) => sidedish.id"
                  :options="sidedish"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group label="Image" label-for="image">
              <validation-provider #default="{ errors }" name="image">
                <b-form-file v-model="image" />
                <img
                  :src="`https://admin.yaal.ch/api/uploads/common/${item.image}`"
                  alt=""
                  width="70px"
                  class="img-size mt-2"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group label="CourierPrice " label-for="CourierPrice">
              <validation-provider
                #default="{ errors }"
                name=" CourierPrice"
                rules="required"
              >
                <b-form-input
                  id="CourierPrice"
                  v-model="item.del_price"
                  placeholder="Courier Price "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="TakeawayPrice " label-for="TakeawayPrice">
              <validation-provider
                #default="{ errors }"
                name=" TakeawayPrice"
                rules="required"
              >
                <b-form-input
                  id="TakeawayPrice"
                  v-model="item.takeaway_price"
                  placeholder="Takeaway Price "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Reservation Price " label-for="reserveprice">
              <validation-provider
                #default="{ errors }"
                name=" reserveprice"
                rules=""
              >
                <b-form-input
                  id="reserveprice"
                  v-model="item.reserve_price"
                  placeholder="Reservation Price "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Banquet Price " label-for="banquetprice">
              <validation-provider
                #default="{ errors }"
                name=" banquetprice"
                rules=""
              >
                <b-form-input
                  id="banquetprice"
                  v-model="item.banquet_price"
                  placeholder="Banquet Price"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Listing" label-for="Choose Variantions">
              <div class="demo-inline-spacing">
                <b-form-checkbox
                  v-model="item.is_buffer"
                  value="1"
                  class="custom-control-success"
                >
                  Buffet
                </b-form-checkbox>

                <b-form-checkbox
                  v-model="item.is_reserv"
                  value="1"
                  class="custom-control-success"
                >
                  Reservation
                </b-form-checkbox>

                <b-form-checkbox
                  v-model="item.is_banquet"
                  value="1"
                  class="custom-control-success"
                >
                  Banquet
                </b-form-checkbox>
              </div>
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <b-col cols="12" class="text-right">
            <b-button type="submit" variant="primary" class="mr-1 mt-1">
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BCard,
  BListGroup,
  BListGroupItem,
  BCardText,
  BFormTextarea,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BForm,
  BButton,
  BFormSelect,
} from "bootstrap-vue";
import vSelect from "vue-select";

import store from "@/store/index";
import { ref, onUnmounted } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import usefoodList from "./usefoodList";
import { required, integer } from "@validations";
import foodStoreModule from "../foodStoreModule";
import axios from "@axios";
export default {
  components: {
    vSelect,
    BFormFile,
    BFormTextarea,
    BFormSelect,
    BCard,
    BAvatar,
    BListGroup,
    BListGroupItem,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    required,
    integer,
  },
  data() {
    return {
      category: [],
      ingredient: [],
      sidedish: [],
      selectedcategory: [],
      selectedingredient: [],
      selectedsidedish: [],
      item: {
        code: null,
        short_text: null,
        long_text: null,
        takeaway_price: null,
        del_price: null,
        name: null,
        is_buffer: null,
        is_banquet: null,
        is_reserv: null,
        image: null,
        reserve_price:null,
        banquet_price:null,

      },
      id: null,
      image: null,
    };
  },
  async mounted() {
    this.id = this.$route.params.id;
    const promises = [axios.get(`admin/getone/food/${this.id}`)];
    const allResponses = await Promise.all(promises);
    this.item.code = allResponses[0].data.data.code;
    this.item.short_text = allResponses[0].data.data.short_text;
    this.item.long_text = allResponses[0].data.data.long_text;
    this.item.takeaway_price = allResponses[0].data.data.takeaway_price;
    this.item.del_price = allResponses[0].data.data.del_price;
    this.item.reserve_price = allResponses[0].data.data.reserve_price;
    this.item.banquet_price = allResponses[0].data.data.banquet_price;

    this.item.name = allResponses[0].data.data.name;
    this.item.image = allResponses[0].data.data.image;

    this.item.is_buffer = allResponses[0].data.data.is_buffer;
    this.item.is_banquet = allResponses[0].data.data.is_banquet;
    this.item.is_reserv = allResponses[0].data.data.is_reserv;

    this.selectedsidedish = allResponses[0].data.data.sidedishId.split(",");
    this.selectedingredient = allResponses[0].data.data.ingredientId.split(",");
    this.selectedcategory = allResponses[0].data.data.categoryId.split(",");

    const promises1 = [axios.get(`admin/get/catinside`)];
    const allResponses1 = await Promise.all(promises1);
    this.category = allResponses1[0].data.category;
    this.ingredient = allResponses1[0].data.ingredient;
    this.sidedish = allResponses1[0].data.sidedish;
  },
  methods: {
    submitForm() {
      this.$refs.addfoodForm.validate().then(async (success) => {
        if (success) {
          let formData = new FormData();

          for (var key in this.item) {
            formData.append(key, this.item[key]);
          }

          formData.append("category", this.selectedcategory.toString());
          formData.append("ingredient", this.selectedingredient.toString());
          formData.append("sidedish", this.selectedsidedish.toString());
          formData.append("image1", this.image);
          formData.append("id", this.id);

          var result = await this.updatefood(formData);
          console.log(result);
          setTimeout(() => {
            history.back();
          }, 1000);
        }
      });
    },
  },
  setup() {
    // Register module
    const APP_STORE_MODULE_NAME = "master-food";

    if (!store.hasModule(APP_STORE_MODULE_NAME))
      store.registerModule(APP_STORE_MODULE_NAME, foodStoreModule);

    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME))
        store.unregisterModule(APP_STORE_MODULE_NAME);
    });

    const { fetchfood, updatefood } = usefoodList();

    return {
      fetchfood,
      updatefood,
    };
  },
};
</script>
